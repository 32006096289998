import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

export default function ReRoute() {
  useEffect(() => navigate('/'), []);

  return (
    <>
      <p>Rerouting back to site...</p>
    </>
  );
}
